import React from "react"
import Layout from "../layout/GeneralLayout"
import { Box, Heading, Paragraph, GlassBox } from "../components/commonElements"
import styled from "styled-components"
import Footer from "../components/footer"
import SEO from "../components/seo"
import FeedbackForm from "../components/feedbackForm"

const Container = styled(GlassBox)`
  font-size: 1.8rem;
  max-width: ${props => `calc(${props.theme.containerSize} - 2rem)`};
  align-items: center;
  min-height: 78vh;
  width: calc(100% - 2rem);
  @media ${props => props.theme.device.tablet} {
    min-height: 83vh;
  }
`

const InputBox = styled(Box)`
  flex: 2;
  max-width: 80rem;
  width: 100%;
  @media (max-width: 1200px) {
    flex: 100%;
  }
`

const contact = () => {
  return (
    <Layout>
      <Box flex="1" mt="1rem" alignItems="center" gap="1rem">
        <Container backgroundColor="white" padding="2rem">
          <Heading level={1} textAlign="center" mb="1rem">
            Contact FlipSimu
          </Heading>
          <Paragraph level={4} textAlign="center" mb="3.4rem">
            We’re ready to answer any and all questions.
          </Paragraph>
          <InputBox>
            <FeedbackForm />
          </InputBox>
        </Container>
        <Footer />
      </Box>
    </Layout>
  )
}

export default contact

export const Head = () => (
  <SEO
    titleP="Contact | FlipSimu"
    descriptionP="This is the contact page of FlipSimu"
    pathname="/contact/"
  />
)
