import emailjs from "@emailjs/browser"

export const sendFeedback = data => {
  const template_params = {
    from_name: data.name,
    reply_to: data.email,
    tool: data.toolChoice,
    rating: data.interestLevel,
    usage: data.usage,
    message: data.message,
  }

  const service_id = process.env.GATSBY_SERVICE_ID
  const template_id = process.env.GATSBY_EMAILJS_TEMPLATE_ID
  const user_id = process.env.GATSBY_EMAILJS_USER_ID

  try {
    emailjs.send(service_id, template_id, template_params, user_id)
  } catch (error) {
    throw error
  }
}
