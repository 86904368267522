import React from 'react'
import { Text, Box } from "../../components/commonElements"
import styled from 'styled-components'

const LabelBox = styled(Box)`
  padding: 0 2rem 0 0;
  @media (max-width: 1200px) {
    padding: 0 0 0.5rem 0;
    flex-grow: 1;
  }
`

const LabelWrapper = ({
  children,
  label,
  labelWidth = '25rem',
  error = null,
}) => {
  return (
    <Box>
      <Box flexDirection="row" flexWrap="wrap" style={{ columnGap: `1.3rem` }}>
        <LabelBox
          width={labelWidth}
          justifyContent="center"
        >
          <Text>{label}</Text>
        </LabelBox>
        <Box flex="1 0 auto">
          {children}
          <Text color="reject" fontSize="1.6rem">
            {error}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default LabelWrapper
