import React, { useState } from "react"
import {
  Input,
  Box,
  Button as ButtonG,
  Rating,
  TextArea as TextAreaG,
} from "../commonElements"
import DropdownMenu from "../commonElements/textDropdownMenu"
import styled from "styled-components"
import LabelWrapper from "./LabelWrapper"
import { useInput } from "../hook/useInput"
import { useToastContext } from "../contextHook/toast"
import { toolsData } from "../../data/text-tools-data"
import { sendFeedback } from "../../services/emailjs"

const Button = styled(ButtonG)`
  margin-bottom: 11.7rem;
  margin-top: 1.8rem;
  background-color: ${props =>
    props.disabled ? "#F2F2F2" : props.theme.colors[props.backgroundColor]};
  color: ${props =>
    props.disabled ? "#BCBABA" : props.theme.colors[props.color]};
  -webkit-tap-highlight-color: transparent;
`

const TextInput = styled(Input)`
  background-color: #f2f2f2;
  border: none;
  border-radius: 0px;
  flex: 1;
  min-width: 28rem;
  min-height: 4rem;
  border-radius: 2px;
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }
`

const TextArea = styled(TextAreaG)`
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  flex: 1;
  min-width: 28rem;
  max-height: 12.4rem;
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }
`

const FeedbackForm = props => {
  const { nameValue, nameBind, nameReset } = useInput("", "name")
  const { emailValue, emailBind, emailReset, emailEmpty } = useInput(
    "",
    "email"
  )
  const { toolValue, toolBind, toolReset, setTool } = useInput(
    toolsData[0].subItems[0].text,
    "tool"
  )
  const { messageValue, messageBind, messageReset, messageEmpty } = useInput(
    "",
    "message"
  )
  const { usageValue, usageBind, usageReset } = useInput("", "usage")
  const { interestValue, interestBind, interestReset, setInterest } = useInput(
    5,
    "interest"
  )
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = React.useState({
    name: false,
    email: false,
    tool: false,
    message: false,
    usage: false,
    interest: false,
  })
  const { setToast } = useToastContext()

  const submit = async () => {
    setErrors({
      ...errors,
      email: emailEmpty,
      message: messageEmpty,
    })
    if (messageEmpty || emailEmpty) {
      return
    }
    setLoading(true)
    const data = {
      name: nameValue,
      email: emailValue,
      toolChoice: toolValue,
      message: messageValue,
      usage: usageValue,
      interestLevel: interestValue,
    }
    try {
      sendFeedback(data)
      setToast("Your form is successfully submitted. Thank you!")
    } catch (error) {
      setToast("There is an error for your submission. Please try again.")
    }

    nameReset()
    emailReset()
    toolReset()
    messageReset()
    usageReset()
    interestReset()
    setLoading(false)
  }

  return (
    <Box gap="2rem" alignItems="stretch" height="100%">
      <LabelWrapper label="Your Name (Required)" size={props.size}>
        <TextInput {...nameBind} placeholder="e.g. Stephano Mile" type="text" />
      </LabelWrapper>
      <LabelWrapper
        label="Your Email (Required)"
        size={props.size}
        error={errors.email && emailEmpty && "Email is required."}
      >
        <TextInput
          {...emailBind}
          placeholder="e.g. stephanomile@gmail.com"
          type="text"
        />
      </LabelWrapper>
      <LabelWrapper label="Pick a tool" size={props.size}>
        <DropdownMenu
          name={toolBind.name}
          onChange={setTool}
          value={toolValue}
          options={toolsData}
        />
      </LabelWrapper>
      <LabelWrapper label="Where do you usually use?" size={props.size}>
        <TextInput {...usageBind} placeholder="e.g. education" />
      </LabelWrapper>
      <LabelWrapper
        label="Share your thoughts (Required)"
        size={props.size}
        error={errors.message && messageEmpty && "Feedback is required."}
      >
        <TextArea
          resize={false}
          {...messageBind}
          placeholder="Please let us know your question, bug report, feature request, or general feedback……"
          rows={5}
        />
      </LabelWrapper>
      <LabelWrapper label="Rating of usefulness" size={props.size}>
        <Rating
          size={47}
          scale={5}
          fillColor="gold"
          strokeColor="#3A3A3A"
          rating={interestValue}
          setRating={setInterest}
          name={interestBind.name}
        />
      </LabelWrapper>

      <Box alignSelf="center" maxWidth="45rem" width="100%">
        <Button disabled={loading} onClick={submit}>
          Send
        </Button>
      </Box>
    </Box>
  )
}

export default FeedbackForm
